const actions = {
  async validateMerchant({ commit }, { validationURL }) {
    try {
      const response = await this.$axios.post(
        'api/v1/transactions/test-apple-pay',
        {
          validationURL,
        },
      );

      return response.data;
    } catch (error) {
      const message = error.response
        ? error.response.data.error
        : error.message;

      commit('setSendDataError', message);
    }
  },
  async pay({ commit }, { paymentToken }) {
    try {
      const response = await this.$axios.post('api/v1/transactions/create', {
        customer: {
          id: '1234',
          taxNumber: '1234567',
          firstName: 'appleFirstName',
          lastName: 'appleLastName',
          email: 'apple@mail.com',
        },
        description: 'apple test',
        transactionType: 'apple-pay',
        currency: 'UAH',
        urls: {
          callbackUrl: 'https://site.com',
        },
        orderId: Date.now().toString(),
        amount: 10,
        applePay: {
          merchantIdentifier: 'merchant.com.slots-pay.prod',
          paymentToken,
        },
      });

      return response.data;
    } catch (error) {
      const message = error.response
        ? error.response.data.error
        : error.message;

      commit('setSendDataError', message);
    }
  },
};

export default {
  namespaced: true,
  actions,
};
